<template>
    <div class="mainbody case">
        <a href="javascript:void(0);" class="scrollT" ref="case"></a>
        <div class="sub-banner" v-if="bannerList.length > 0" :style="{'backgroundImage':'url('+ bannerList[0].pic +')'}">
			<div class="container">
				<div class="main">
					<h2 class="wow fadeInDown">Successful Cases</h2>
				</div>
			</div>
		</div>
        <div class="case-main">
            <div class="container">
                <h3 class="wow fadeInLeft">Successful Cases</h3>
                <div class="right">
                    <ul class="case-list clearfix">
                        <li class="wow fadeInUp"
                            v-for="(item,index) in caseList"
                            :key="item.id"
                            :data-wow-delay="(index + 1)/5 + 's'">
                            <div class="imgBg motion" :style="{'backgroundImage':'url('+item.pic+')'}"></div>
                            <div class="main">
                                <div class="name">{{ item.title }}</div>
                                <div class="txt">{{ item.detail }}</div>
                            </div>
                        </li>
                    </ul>
                    <el-pagination class="wow fadeInRight"
                        v-if="total >= 10"
                        layout="prev, pager, next"
                        :page-size="conditionObj.rows"
                        :current-page="conditionObj.p"
                        :total="total"
                        @current-change="changePage">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { Pagination } from 'element-ui'
    Vue.use( Pagination )
    import { WOW } from 'wowjs'
    import { getCaseDataList, getBannerList } from '../../request/api'
    export default {
        name:'Case',
        metaInfo:{
            title:"DMEGC Solar-case",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        props: [ 'p' ],
        data(){
            return {
                conditionObj:{
                    rows:10,
                    p:1
                },
                total: 0,
                caseList: [],
                bannerList: []
            }
        },

        watch:{
            $route:function(){

                this.showCaseData()
            }
        },
        mounted(){
            getBannerList({cate:'anli'}).then( res => {

                this.bannerList = res.data
            })
            this.showCaseData();
        },
        methods: {
            showCaseData(){
                if(this.p === undefined){
                    
                    this.conditionObj.p = 1
                }else{

                    this.conditionObj.p = parseInt(this.p)
                }

                getCaseDataList( this.conditionObj ).then( res => {

                    this.caseList = res.data.rows
                    this.total = parseInt( res.data.count )
                    this.$nextTick(() => {

                        const wow = new WOW({
                            animateClass: 'animated',
                            offset: 100
                        })
                        wow.init();
                        this.$refs.case.scrollIntoView();
                    })
                })
            },
            changePage(val){
                this.$router.push({
                    name: 'case',
                    query:{
                        p:val
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.case{
    position: relative;
}
.case-main{
    padding: 4.1667vw 0;
    .container{
        display: flex;
        justify-content: space-between;
    }
    .case-list{
        padding-bottom: 30px;
        li{
            float: left;
            width: calc(50% - 1px);
            height: 10.5208vw;
            margin: 0 2px 2px 0;
            overflow: hidden;
            &::before{
                content: '';
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                border:3px solid #E60011;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 16;
                opacity: 0;
                transition:all 0.5s ease-in-out;
                -webkit-transition:all 0.5s ease-in-out;
                -moz-transition:all 0.5s ease-in-out;
                -o-transition:all 0.5s ease-in-out;
            }
            &:nth-child(2n){
                margin-right: 0;
            }
            position: relative;
            .main{
                position: relative;
                z-index: 10;
                padding: 1.0417vw;
            }
            .name{
                font-size: 1.5625vw;
                line-height: 2.5vw;
                color: #fff;
            }
            .txt{
                font-size: 0.9375vw;
                line-height: 1.3542vw;
                margin-top: 1.0417vw;
                color: #fff;
                font-weight: 300;
            }
            .imgBg{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            &:hover{
                .imgBg{
                    transform: scale(1.1,1.1);
                }
                .name,
                .txt{
                    color: #fff;
                }
                &::before{
                    opacity: 1;
                }
            }
        }
    }
    .el-pagination{
        padding: 0;
        text-align: right;
        /deep/.el-pager{
            .active{
                color: #E60011;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .case-main{
        padding: .6rem 0 .8rem;
        .container{
            display: block;
        }
        .case-list{
            padding-bottom: .6rem;
            li{
                float: none;
                width: 100%;
                margin-right: 0 !important;
                height: 2.5rem;
                &::before{
                    border-width: 2px;
                }
                .main{
                    padding: .4rem;
                }
                .name{
                    font-size: .3rem;
                    line-height: .5rem;
                }
                .txt{
                    font-size: .26rem;
                    line-height: .44rem;
                    margin-top: .1rem;
                }
            }
        }
    }
}
</style>